import Image from "next/image";

export function AppLinkContainer() {
  return (
    <div className="h-[44px] my-8 gap-4 justify-center flex">
      <a
        className="hover"
        aria-label="Google Play"
        rel="noreferrer"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=pt.psiworks.user&pli=1"
      >
        <Image
          height={44}
          src="/img/google-play.png"
          alt="Google Play"
          width={149}
        />
      </a>
      <a
        className="hover"
        aria-label="AppStore"
        rel="noreferrer"
        target="_blank"
        href="https://apps.apple.com/us/app/psiworks-patients/id6624309046"
      >
        <Image
          height={44}
          width={131}
          src="/img/appstore-badge.svg"
          alt="Appstore"
        />
      </a>
    </div>
  );
}
